var state = {
  form: {}
};
var mutations = {
  RESET_FORM: function RESET_FORM(state) {
    state.form = {};
  },
  SET_FORM: function SET_FORM(state, form) {
    state.form = form;
  }
};
var actions = {
  resetForm: function resetForm(_ref) {
    var commit = _ref.commit;
    commit('RESET_FORM');
  },
  setForm: function setForm(_ref2, form) {
    var commit = _ref2.commit;
    commit('SET_FORM', form);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};