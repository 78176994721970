exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"menuText": "#4a5974",
	"menuActiveText": "#55bc8a",
	"subMenuActiveText": "#55bc8a",
	"menuBg": "#eff4f9",
	"menuHover": "#eff4f9",
	"subMenuBg": "#eff4f9",
	"subMenuHover": "#eff4f9",
	"sideBarWidth": "240px"
};