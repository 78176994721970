var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("search", { staticClass: "right-menu-item" }),
                _vm._v(" "),
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect",
                }),
                _vm._v(" "),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                }),
                _vm._v(" "),
                _c("biz-list", { staticClass: "right-menu-item hover-effect" }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "avatar-container right-menu-item hover-effect" },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" } },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" },
                    }),
                    _vm._v(" "),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/" } },
                        [
                          _c("el-dropdown-item", [
                            _vm._v(
                              "\n              " +
                                _vm._s("首页") +
                                "\n            "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-dropdown-item", { attrs: { divided: "" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: { display: "block" },
                            on: { click: _vm.logout },
                          },
                          [_vm._v(_vm._s("登出"))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }