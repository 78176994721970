import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/app/node_modules/core-js/modules/es6.array.iterator.js";
import "/app/node_modules/core-js/modules/es6.promise.js";
import "/app/node_modules/core-js/modules/es6.object.assign.js";
import "/app/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/errorLog"; // error log

import * as filters from "./filters"; // global filters

import iconPicker from 'e-icon-picker';
import 'e-icon-picker/dist/symbol.js'; // 基本彩色图标库
import 'e-icon-picker/dist/index.css'; // 基本样式，包含基本图标
import 'font-awesome/css/font-awesome.min.css'; // font-awesome 图标库
import 'element-ui/lib/theme-chalk/icon.css'; // element-ui 图标库
import VueClipboard from 'vue-clipboard2';
import dayjs from 'dayjs';
Vue.use(iconPicker, {
  FontAwesome: true,
  ElementUI: true,
  eIcon: true,
  eIconSymbol: true
});
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
});

// register global utility filters.
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use({
  install: function install(Vue, options) {
    Vue.prototype.$clone = function (o) {
      // deep clone
      return JSON.parse(JSON.stringify(o));
    };
    Vue.prototype.formatDate = function (field) {
      var arr = field.split('.');
      var fieldFunc = function fieldFunc(row) {
        var value = row;
        for (var i = 0; i < arr.length; i++) {
          value = value[arr[i]];
        }
        return value;
      };
      return function (row) {
        if (!row) {
          return '';
        }
        var value = fieldFunc(row);
        if (!value) {
          return '';
        }
        return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
      };
    };
  }
});
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});