import _objectSpread from "/app/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from '@/styles/variables.scss';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['sidebar'])), {}, {
    tree: function tree() {
      return this.$store.state.route.tree;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};